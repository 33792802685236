<template>
  <div class="dingding">
    <Card style="width: 100%;">
      <div style="text-align:center">
        <img src="https://manage.jiaofubao.net/static/img/larke.e7fa8d14.png">
        <h3>{{ title }}</h3>
      </div>
    </Card>
    <Row v-if="!isNotBind">
      <Col span="24">
        <Alert type="success">你好，[{{ nickname }}] 您已授权！我们将持续为您推送最新动态。</Alert>
      </Col>
    </Row>
    <Collapse v-model="value" accordion>
        <Panel 
          :name="item.name"
          v-for="item,index in PanelList"
          :key="index">
          {{ item.title }}
          <template #content>
            <p class="panel-content">
              {{ item.discription }}
            </p>
          </template>
        </Panel>
    </Collapse>
    <Row v-if="isNotBind">
      <Col span="24" class="auth-click">
        <Button type="primary" @click="handleClick">我要授权</Button>
      </Col>
    </Row>
    <Row v-else>
      <Col span="24" class="auth-click">
        <Button type="primary" @click="handleCancelClick">取消授权</Button>
      </Col>
    </Row>
    <Row class="footer">
      <Col span="24" class="footer-content">Copyright©2018 苏州企之初网络科技有限公司</Col>
    </Row>
    <Modal
      v-model="modal1"
      title="客如潮钉钉工作通知授权"
      width="300"
      @on-ok="onOk">
      <p>您确定要授权客如潮向您发送通知吗？</p>
    </Modal>
    <Modal
      v-model="modal2"
      title="客如潮钉钉工作通知授权确认"
      width="300"
      :mask-closable="false"
      @on-ok="onOk2">
        <Form :model="formItem" :label-width="60">
          <FormItem label="公司名">
            <Input v-model="formItem.companyName" placeholder="公司名"></Input>
          </FormItem>
          <FormItem label="用户名">
            <Input v-model="formItem.nickname" placeholder="用户名"></Input>
          </FormItem>
          <FormItem label="手机号">
            <Input v-model="formItem.mobile" placeholder="手机号"></Input>
          </FormItem>
        </Form>
        <Text type="danger">*如果发现于您的信息不符，请手动填写正确，否则将不能正确接收到通知！</Text>
        <template #footer>
            <Button type="info" size="large" long :loading="modal_loading" @click="onOk2">确认授权</Button>
        </template>
    </Modal>
  </div>
</template>

<script>
import * as dd from 'dingtalk-jsapi' // 此方式为整体加载，也可按需进行加载
import { openAuthMiniApp } from 'dingtalk-design-libs/biz/openAuthMiniApp'
import { getENV, } from 'dingtalk-jsapi/lib/env';
import { compareVersion, } from 'dingtalk-jsapi/lib/sdk/sdkLib';
const { platform, version, appType, } = getENV();

export default {
  name: '消息通知',
  data() {
    return {
      title: '客如潮钉钉通知系统',
      value: '1',
      PanelList: [{
        name: '1',
        title: '什么是客如潮钉钉通知系统?',
        discription: '客如潮钉钉通知系统是一个可以将客如潮中的通知同步推送到您的钉钉工作通知中的系统。'
      }, {
        name: '2',
        title: '为什么要使用客如潮钉钉通知系统?',
        discription: '客如潮钉钉通知系统可以将客如潮中的通知同步推送到您的钉钉工作通知中，让您时刻掌握到订单或工单的最新状态，不会错过任何一个工作机会。'
      }, {
        name: '3',
        title: '怎样才能使用客如潮钉钉通知系统?',
        discription: '进入此页面后点击我要授权按钮进行授权操作，进行账号绑定操作，授权之后您就可以接收到客如潮系统通知了。'
      }],
      modal1: false,
      modal2: false,
      corpId: '',
      formItem:{
        companyName: '',
        nickname: '',
        mobile: '',
        userid: '',
        unionId: '',
        openId: '',
      },
      modal_loading: false,
      company_id: 0,
      baseUrl: process.env.VUE_APP_BASE_URL,
      isNotBind: true,
      nickname: '',
      userid: '',
      unionId: '',
      userInfo: []
    }
  },
  created() {
    this.checkInfo()
    this.company_id = this.getQueryString('compid') || 0
  },
  methods: {
    checkInfo(){
      if(!this.isAuthSDKSupport()){
        return this.$Message.error('请将钉钉升级到6.0.5以上')
      }
      dd.ready(_ => {
        let corpId =  this.getQueryString('corpId') || ''
        dd.runtime.permission.requestAuthCode({
            corpId: corpId,
            onSuccess: res => {
              let { code } = res
              this.axios.get(`/home-api/ding/check_bind`, {
                params: {
                  code: code,
                  corpid: corpId
                }
              }).then(({ data:{ data:{ status, data }}}) => {
                if (status && data) {
                  this.userInfo = data
                  this.nickname = data.nick
                  this.userid = data.userid
                  this.unionId = data.unionId
                  this.isNotBind = false
                }
              })
            }
        })
      })
    },
    isAuthSDKSupport() {
      return compareVersion(version, '6.0.5')
    },
    onOk2() {
      let corpId =  this.getQueryString('corpId') || ''
      let reg = /^1[3456789]\d{9}$/
      if(!this.formItem.mobile) {
        return this.$Message.error('请填写手机号')
      }
      if (!reg.test(this.formItem.mobile)) {
        return this.$Message.error('手机号格式不正确')
      }
      this.modal_loading = true
      this.formItem.corpid = corpId
      this.axios.post(`/home-api/ding/bind`, {
        ...this.formItem
      }).then(({ data:{ code, message }}) => {
        if(code){
          this.modal_loading = false
          return this.$Message.error(message)
        }
        this.modal2 = false
        this.modal_loading = true
        this.$Message.success(message)
        this.checkInfo()
      })
    },
    handleCancelClick(){
      dd.ready(_ => {
        openAuthMiniApp({
          path:'pages/cancel/index',
          extraData:{
            clientId:'suitelttivpqrkxgl8ari',
            ext: '{}',
            from: 'https://dtalk.jiaofubao.net'
          }
        }).then(_ => {
          let corpId =  this.getQueryString('corpId') || ''
          this.axios.post(`/home-api/ding/unbind`, {
            corpid: corpId,
           ...this.userInfo
          }).then(_ => {
            this.$Message.success('授权取消成功')
            setTimeout(_ => {
              location.reload()
            }, 2e3)
          })
        })
      })
    },
    onOk(){
      dd.ready(_ => {
        let corpId =  this.getQueryString('corpId') || ''
        if (!corpId) {
          return this.$Message.error('获取corpId失败，请稍后重试！')
        }
        openAuthMiniApp({
          panelHeight: 'percent75',
          path: 'pages/home/home', //不要改,这里是小程序dingwlanwvdmrtjjwdmd下的一个页面地址
          extraData:{
              clientId: 'suitelttivpqrkxgl8ari', // 应用ID(唯一标识)
              rpcScope:'Contact.User.Read',
              fieldScope:'Contact.User.mobile',
              type: 0,
              ext: JSON.stringify({company_id: this.company_id}),
              from: 'https://dtalk.jiaofubao.net'
          }
        }).then(({result:{ authCode }}) => {
          // 处理返回数据
          this.axios.get(`/home-api/ding/info`, {
            params: {
              code: authCode,
              corpid: corpId
            }
          }).then(({ data:{ code, data, massage }}) => {
            if (code) {
              return this.$Message.error(massage)
            }
            this.formItem.companyName = data.companyName
            this.formItem.nickname = data.nick
            this.formItem.mobile = data.mobile
            this.formItem.userid = data.userid
            this.formItem.openId = data.openId
            this.formItem.unionId = data.unionId
            this.modal2 = true
          })
        })
      })
    },
    handleClick() {
      this.modal1 = true
    },
    getQueryString(name){
      var reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)")
      var r = window.location.search.substr(1).match(reg)
      if(r!=null)return unescape(r[2]); return null
    }
  },
}
</script>

<style lang="less" scoped>
.dingding {
  position: relative;
  font-size: @font-size;
  .panel-content {
    text-indent: 2em;
  }
  .auth-click {
    text-align: center;
    margin-top: 20px;
  }
  .footer{
    position: fixed;
    height: 40px;
    width: 100%;
    bottom: 0;
    line-height: 40px;
    .footer-content {
      width: 100%;
      margin: 0 auto;
      text-align: center;
      font-size: 12px;
    }
  }
}
</style>
