<template>
  <Demo/>
</template>

<script>
import Demo from '@/components/Demo.vue'

export default {
  name: 'HomeView',
  components: {
    Demo
  }
}
</script>
